<template>
  <div>
    <div class="row">
    <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="LBL0001165"
      :columns="gridColumns"
      :gridHeight="setheight"
      :data="process.charmResultScenarios"
      :merge="scenarioViewGroup==='L' ? grid.merge : []"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :isExcelDown="scenarioViewGroup==='L'"
      :isFullScreen="scenarioViewGroup==='L'"
      :customTrClass="setTrClass"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramCharmResultAssessScenarioId"
      @callbackProxy="callbackProxy"
    >
      <!-- @innerBtnClicked="innerBtnClicked" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-update-btn 
            name="updateBtn"
            :data="updateBtnData"
            :btnEditable="btnEditable"
            :flagCondition="flagCondition"
            @back="back"
          />
          <!-- 이전평가결과조회 -->
          <c-btn 
            v-show="isCompared" 
            label="LBL0001166" 
            icon="compare_arrows" 
            @btnClicked="compareScenario" />
            <!-- 평가완료 -->
          <c-btn
            v-show="editable&&!disabled&&!btnEditable&&process.charmResultScenarios&&process.charmResultScenarios.length>0"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="process"
            mappingType="PUT"
            label="LBL0001162" 
            icon="check"
            @beforeAction="completeProcess"
            @btnCallback="completeProcessCallback" />
          <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeScenario" />
          <c-btn 
            v-show="editable&&!disabled&&scenarioViewGroup==='L'&&process.charmResultScenarios&&process.charmResultScenarios.length>0" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="process.charmResultScenarios"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveScenario"
            @btnCallback="saveScenarioCallback" />
        </q-btn-group>
      </template>
      <template v-slot:suffixTitle>
        <q-btn-group outline class="scenario-view-group">
          <q-btn 
            :outline="scenarioViewGroup!=='L'"
            :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
            size="11px" label="" icon="view_list"
            @click="listView"
          >
            <q-tooltip>
              <!-- 목록형 -->
              {{$label('LBL0001167')}}
            </q-tooltip>
          </q-btn>
          <q-btn 
            :outline="scenarioViewGroup!=='C'"
            :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
            size="11px" label="" icon="space_dashboard"
            @click="cardView"
          >
            <q-tooltip>
              <!-- 카드형 -->
              {{$label('LBL0001168')}}
            </q-tooltip>
          </q-btn>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable && Boolean(props.row.ramCharmResultAssessScenarioId)"
            :requestContentsCols="requestContentsCols"
            tableKey="ramCharmResultAssessScenarioId"
            ibmTaskTypeCd="ITT0000024"
            ibmTaskUnderTypeCd="ITTU000035"
            @imprChange="imprChange"
          />
        </template>
        <template v-else-if="col.name==='item'">
          <q-item class="card-scenario-list">
            <q-item-section>
              <q-item-label class="scenario-card-title">{{`${props.row['materialName']} `}}</q-item-label>
            </q-item-section>

            <q-item-section side>
              <q-icon name="arrow_forward_ios" class="text-primary"
              style="font-size: 20px;"
              @click="getDetail(props.row)"
              ></q-icon>
            </q-item-section>
          </q-item>
        </template>
        <template v-else-if="col.name==='picture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-primary" 
            name="image"
            @click.stop="openImprPicture(props)" />
        </template>
        <!-- <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template> -->
      </template>
    </c-table>
    </div>
    <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
      <q-form ref="editForm">
        <div class="row">
          <div class="col-12">
            <!-- 개선정보 -->
            <c-card title="LBL0001172" class="cardClassDetailForm no-margin">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="editable&&!disabled" 
                    :isSubmit="isSaveUnit"
                    :url="saveUnitUrl"
                    :param="scenario"
                    mappingType="PUT"
                    label="LBLSAVE" 
                    icon="save"
                    @beforeAction="saveScenarioUnit"
                    @btnCallback="saveScenarioUnitCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">    
                <div class="col-4">
                  <!-- 개선 전 사진 -->
                  <c-upload-picture
                    height="160px"
                    :attachInfo="attachBeforeInfo"
                    :editable="editable&&!disabled&&selectScenario">
                  </c-upload-picture>
                </div>
                <div class="col-4">
                  <!-- 현재안전보건조치 -->
                  <c-textarea
                    :disabled="disabled||!selectScenario"
                    :editable="editable"
                    :rows="2"
                    label="LBL0001148"
                    name="existingRiskManagementActivities"
                    v-model="scenario.existingRiskManagementActivities">
                  </c-textarea>
                  <!-- 감소대책 -->
                  <c-textarea
                    style="position: relative; top: -15px;"
                    :disabled="disabled||!selectScenario"
                    :editable="editable"
                    :rows="2"
                    label="LBL0001149"
                    name="addingRiskManagementActivies"
                    v-model="scenario.addingRiskManagementActivies">
                  </c-textarea>
                </div>
                <div class="col-4" style="position: relative; top: -20px;">
                  <div class="formLabelTitle mb-2" style="font-size: 0.9em !important;">개선 전 위험도</div>
                  <q-list dense bordered separator>
                    <q-item>
                      <div class="card-risk-info-charm detail-risk">
                        <q-item-section>
                          <!-- 노출수준 -->
                          <q-item-label>{{$label('LBL0001142')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side>
                          <q-item-label>{{scenario.beforeExposureLevelFinal}}</q-item-label>
                        </q-item-section>
                      </div>
                      <q-separator spaced vertical />
                      <div class="card-risk-info-charm detail-risk">
                        <q-item-section>
                          <!-- 유해성 -->
                          <q-item-label>{{$label('LBL0001127')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side>
                          <q-item-label>{{scenario.beforeToxic}}</q-item-label>
                        </q-item-section>
                      </div>
                    </q-item>
                    <!-- <q-separator spaced inset /> -->
                    <q-item class="detail-risk">
                      <q-item-section>
                        <!-- 위험도 -->
                        <q-item-label>{{$label('LBL0001011')}}</q-item-label>
                      </q-item-section>

                      <q-item-section side class="detail-risk-num-charm">
                        <q-item-label>{{scenario.beforeRiskEstimation}}</q-item-label>
                      </q-item-section>
                      <q-item-section v-if="!disabled&&selectScenario" side class="detail-risk-num">
                        <q-btn 
                          unelevated round dense
                          size="10px"
                          color="primary" 
                          icon="create">
                          <q-popup-proxy ref="beforeRiskEstimationProxy">
                            <component
                              :is="riskPopComponent"
                              width="800px"
                              :props="{
                                row: {
                                  ramMatrixId: scenario.ramMatrixId
                                }
                              }"
                              :editable="editable&&!disabled&&selectScenario"
                              @callback="(data, color) => callbackCardProxy(data, color, 'beforeRiskEstimationProxy')"
                            />
                          </q-popup-proxy>
                        </q-btn>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
                <div class="col-12" style="position: relative; top: -45px;">
                  <!-- 개선 목록 -->
                  <c-table
                    title="LBL0001174"
                    :editable="editable&&!disabled&&selectScenario"
                    :columns="gridImpr.columns"
                    :data="scenario.imprs"
                    :gridHeight="gridImpr.height"
                    :usePaging="false"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :filtering="false"
                    :isExcelDown="false"
                    @linkClick="(row) => imprClick({ sopImprovementId: row.sopImprovementId, ramCharmResultAssessImprId: row.ramCharmResultAssessImprId }, row)"
                  >
                    <template slot="table-button">
                      <q-btn-group outline>
                        <!-- 개선요청 -->
                        <c-btn 
                          v-if="editable&&!disabled&&selectScenario" 
                          label="LBLIMPRREQUEST" 
                          icon="add" 
                          @btnClicked="openImprRequest" />
                      </q-btn-group>
                    </template>
                  </c-table>
                </div>
                
                <div class="col-4" style="position:relative; top:-35px;">
                  <!-- 개선 후 사진 -->
                  <c-upload-picture
                    height="160px"
                    isMultiTask
                    :attachInfo="attachAfterInfo"
                    :editable="editable&&!disabled&&selectScenario">
                  </c-upload-picture>
                </div>
                <div class="col-4 row" style="position:relative; top:-35px;">
                  <div class="col-6">
                    <!-- 평가일 -->
                    <c-datepicker
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001013"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가자 -->
                    <c-field
                      v-if="!param.vendorFlag"
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :data="scenario"
                      label="LBL0001014"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                  </div>
                  <div class="col-12">
                    <!-- 위험등록부 -->
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="LBL0000369"
                      name="riskbookFlag"
                      v-model="scenario.riskbookFlag"
                    />
                  </div>
                </div>
                <div class="col-4" style="position: relative; top: -55px;">
                  <div class="formLabelTitle mb-2" style="font-size: 0.9em !important;">개선 후 위험도</div>
                  <q-list dense bordered separator>
                    <q-item>
                      <div class="card-risk-info-charm detail-risk">
                        <q-item-section>
                          <!-- 노출수준 -->
                          <q-item-label>{{$label('LBL0001142')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side>
                          <q-item-label>{{scenario.afterExposureLevelFinal}}</q-item-label>
                        </q-item-section>
                      </div>
                      <q-separator spaced vertical />
                      <div class="card-risk-info-charm detail-risk">
                        <q-item-section>
                          <!-- 유해성 -->
                          <q-item-label>{{$label('LBL0001127')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side>
                          <q-item-label>{{scenario.afterToxic}}</q-item-label>
                        </q-item-section>
                      </div>
                    </q-item>
                    <!-- <q-separator spaced inset /> -->
                    <q-item class="detail-risk">
                      <q-item-section>
                        <!-- 위험도 -->
                        <q-item-label>{{$label('LBL0001011')}}</q-item-label>
                      </q-item-section>

                      <q-item-section side class="detail-risk-num-charm">
                        <q-item-label>{{scenario.afterRiskEstimation}}</q-item-label>
                      </q-item-section>
                      <q-item-section v-if="!disabled&&selectScenario" side class="detail-risk-num">
                        <q-btn 
                          unelevated round dense
                          size="10px"
                          color="primary" 
                          icon="create">
                          <q-popup-proxy ref="afterRiskEstimationProxy">
                            <component
                              :is="riskPopComponent"
                              width="800px"
                              :props="{
                                row: {
                                  ramMatrixId: scenario.ramMatrixId
                                }
                              }"
                              :editable="editable&&!disabled&&selectScenario"
                              @callback="(data, color) => callbackCardProxy(data, color, 'afterRiskEstimationProxy')"
                            />
                          </q-popup-proxy>
                        </q-btn>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </template>
            </c-card>
          </div>
        </div>
      </q-form>
    </div>
    </div>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'charm-scenario',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workCycleName: '',  // 작업발생주기
        workingTime: 0,  // 작업시간(1회)
        ramMatrixId: '',
        temperature: null,
        workMeasurementPlanId: '',
        measPlanContent: '',
        estimationFlag: '',
        charmResultScenarios: [],
        charmEstimationScenarios: [],
        resultReCheckedCnt: 0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramTechniqueCd:'',
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    // btnEditable: {
    //   type: Boolean,
    //   default: function() {
    //     return false;
    //   },
    // },
    updateBtnData: {
      type: Object,
      default: () => ({
        title: 'LBL0001164', // 평가
        flag: false,
        research: '',
      }),
    },
    estimationUpdateBtnData: {
      type: Object,
      default: () => ({
        title: 'LBL0001204', // 추정
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: '' }
        ],
        data: [],
        // columns: [
        //   {
        //     fix: true,
        //     name: 'materialName',
        //     field: 'materialName',
        //     label: '화학자재',
        //     align: 'left',
        //     style: 'width:150px',
        //     sortable: false,
        //   },
        //   {
        //     fix: true,
        //     name: 'existingRiskManagementActivities',
        //     field: 'existingRiskManagementActivities',
        //     label: '현재안전보건조치',
        //     align: 'left',
        //     type: 'textarea',
        //     style: 'width:300px',
        //     sortable: false,
        //   },
        //   {
        //     name: 'before',
        //     field: 'before',
        //     label: '개선전',
        //     align: 'center',
        //     child: [
        //       {
        //         name: 'beforeExposureLevelFinal',
        //         field: 'beforeExposureLevelFinal',
        //         label: '노출수준',
        //         align: 'center',
        //         style: 'width:45px',
        //         sortable: false
        //       },
        //       {
        //         name: 'beforeToxic',
        //         field: 'beforeToxic',
        //         label: '유해성',
        //         align: 'center',
        //         style: 'width:50px',
        //         sortable: false
        //       },
        //       {
        //         name: 'beforeRiskEstimation',
        //         field: 'beforeRiskEstimation',
        //         label: '위험도',
        //         align: 'center',
        //         style: 'width:70px',
        //         sortable: false,
        //         type: 'proxy',
        //         component: () => import(`${'@/pages/ram/base/matrixPop.vue'}`)
        //       },
        //     ]
        //   },
        //   {
        //     name: 'addingRiskManagementActivies',
        //     field: 'addingRiskManagementActivies',
        //     label: '감소대책',
        //     align: 'left',
        //     type: 'textarea',
        //     style: 'width:300px',
        //     sortable: false,
        //   },
        //   {
        //     name: 'after',
        //     field: 'after',
        //     label: '개선후',
        //     align: 'center',
        //     child: [
        //       {
        //         name: 'afterExposureLevelFinal',
        //         field: 'afterExposureLevelFinal',
        //         label: '노출수준',
        //         align: 'center',
        //         style: 'width:45px',
        //         sortable: false
        //       },
        //       {
        //         name: 'afterToxic',
        //         field: 'afterToxic',
        //         label: '유해성',
        //         align: 'center',
        //         style: 'width:50px',
        //         sortable: false
        //       },
        //       {
        //         name: 'afterRiskEstimation',
        //         field: 'afterRiskEstimation',
        //         label: '위험도',
        //         align: 'center',
        //         style: 'width:70px',
        //         sortable: false,
        //         required: true,
        //         type: 'proxy',
        //         component: () => import(`${'@/pages/ram/base/matrixPop.vue'}`)
        //       },
        //     ]
        //   },
        //   {  
        //     name: 'assessDate',
        //     field: 'assessDate',
        //     label: '평가일',
        //     align: 'center',
        //     type: 'date',
        //     style: 'width:120px',
        //     sortable: false,
        //     required: true,
        //   },
        //   {
        //     name: 'assessUserName',
        //     field: 'assessUserName',
        //     label: '평가자',
        //     align: 'center',
        //     type: 'user',
        //     userId: 'assessUserId',
        //     style: 'width:100px',
        //     sortable: false,
        //     required: true,
        //   },
        //   {
        //     name: 'riskbookFlag',
        //     field: 'riskbookFlag',
        //     label: '위험등록부',
        //     align: 'center',
        //     style: 'width:80px',
        //     sortable: true,
        //     type: 'check',
        //     'true': 'Y',
        //     'false': 'N',
        //     disableTarget: 'riskRegisterFlag',
        //     disableCon: false
        //   },
        //   {
        //     name: 'impr',
        //     field: 'impr',
        //     label: '개선진행',
        //     align: 'center',
        //     child: [
        //       {
        //         name: 'customCol',
        //         field: 'customCol',
        //         label: 'LBLIMPRNO_PROGRESS',
        //         align: 'center',
        //         style: 'width:300px',
        //         type: 'custom',
        //         sortable: false
        //       },
        //     ]
        //   },
        // ],
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:85px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            // 요청부서
            label: 'LBL0000927',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
        ],
        height: '240px'
      },
      scenario: {
        ramCharmResultAssessScenarioId: '', // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        mdmChemMaterialId: '',
        materialName:'',
        existingRiskManagementActivities:'', // 현재안전보건조치
        addingRiskManagementActivies:'', // 추가 리스크 관리활동
        beforeRamMatrixRiskId: '',  // 전 위험도 no
        afterRamMatrixRiskId: '',  // 후 위험도 no
        assessDate:'', // 평가일
        assessUserId: '',  // 평가자 ID
        assessUserName:'', // 평가자명
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        riskbookFlag:'',  
        customCol:'',
        imprs: [], // 개선목록
      },
      riskPopComponent: () => import(`${'@/pages/ram/base/matrixPop.vue'}`),
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'CHARM_SCENARIO_BEFORE',
        taskKey: '',
      },
      activeWinProps: {
        processCd: '',
        ramCharmResultAssessScenarioId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      attachAfterInfo:{ 
        isSubmit: '',
        taskClassCd: 'CHARM_SCENARIO_AFTER',
        taskKey: '',
      },
      scenarioViewGroup: 'L',
      rowIndex: 0,
      editable: true,
      isSave: false,
      isSaveUnit: false,
      isComplete: false,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      saveUnitUrl: '',
      listImprUrl: '',
      deleteUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  computed: {
    isCompared() {
      return this.process.resultReCheckedCnt > 0;
    },
    disabled() { // 완료가 아니면 수정가능
      return this.param.ramStepCd == 'RRS0000015' && this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 130) + 'px' : '500px'
    },
    btnEditable() {
      return this.editable && this.param.ramStepCd === 'RRS0000010' && this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
    flagCondition() {
      return this.param.ramStepCd === 'RRS0000010' // 
    },
    selectScenario() {
      return Boolean(this.scenario.ramCharmResultAssessScenarioId)
    },
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
    requestContentsCols() {
      return ['materialName'];
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            fix: true,
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0000356',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전보건조치
            label: 'LBL0001148',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeExposureLevelFinal',
                field: 'beforeExposureLevelFinal',
                // 노출수준
                label: 'LBL0001142',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'beforeToxic',
                field: 'beforeToxic',
                // 유해성
                label: 'LBL0001127',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'beforeRiskEstimation',
                field: 'beforeRiskEstimation',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
                type: 'proxy',
                component: this.riskPopComponent
              },
            ]
          },
          {
            name: 'addingRiskManagementActivies',
            field: 'addingRiskManagementActivies',
            // 감소대책
            label: 'LBL0001149',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterExposureLevelFinal',
                field: 'afterExposureLevelFinal',
                // 노출수준
                label: 'LBL0001142',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'afterToxic',
                field: 'afterToxic',
                // 유해성
                label: 'LBL0001127',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'afterRiskEstimation',
                field: 'afterRiskEstimation',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
                required: true,
                type: 'proxy',
                component: this.riskPopComponent
              },
            ]
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
          {  
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
            required: true,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:100px',
            sortable: false,
            required: true,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험등록부
            label: 'LBL0000369',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            disableTarget: 'riskRegisterFlag',
            disableCon: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:190px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ]
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            // 평가내용
            label: 'LBL0001179',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    }
  },
  watch: {
    'estimationUpdateBtnData.research'() {
      this.getList();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.charm.scenario.result.url
      this.detailUrl = selectConfig.ram.charm.scenario.get.url
      this.listImprUrl = selectConfig.ram.charm.riskReduce.impr.url
      this.saveUrl = transactionConfig.ram.charm.scenario.result.url
      this.saveUnitUrl = transactionConfig.ram.charm.scenario.saveUnit.url
      this.deleteUrl = transactionConfig.ram.charm.scenario.delete.url
      this.completeUrl = transactionConfig.ram.assessProcess.complete.url;
      // code setting
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,
      }
      this.$http.request((_result) => {
        this.process.charmResultScenarios = _result.data;
      },);
    },
    getDetail(row) {
      this.scenario = row
      this.$http.url = this.$format(this.detailUrl, row.ramCharmResultAssessScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);
        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.ramCharmResultAssessScenarioId)
        //this.$set(this.attachAfterInfo, 'taskKey', this.scenario.ramCharmResultAssessScenarioId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      this.$http.url = this.listImprUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramCharmResultAssessScenarioId: this.scenario.ramCharmResultAssessScenarioId,
      }
      this.$http.request((_result) => {
        this.$set(this.scenario, 'imprs', _result.data)
      },);
    },
    openImprRequest() {
      let requestContents = this.scenario.materialName
      
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,       
        relationTableKey: this.scenario.ramCharmResultAssessScenarioId,
        ibmTaskTypeCd: 'ITT0000024',
        ibmTaskUnderTypeCd: 'ITTU000035',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    closeCardImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprs();
    },
    research() {
      this.$emit('research', 'scenario')
    },
    /* eslint-disable no-unused-vars */
    imprChange(data) {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    innerBtnClicked(col, props, btn) {
      if (col.name === 'customCol') {
        this.requestImprRow = props.row
        let requestContents = props.row.materialName
        this.popupOptions.title = 'LBLIMPRREQUEST';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.ramCharmResultAssessScenarioId,
          ibmTaskTypeCd: 'ITT0000024',
          ibmTaskUnderTypeCd: 'ITTU000035',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
            this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
              this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
            this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
              this.requestImprRow.ibmTooltip + '│' + item.ibmTooltip : item.ibmTooltip
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    listView() {
      this.scenarioViewGroup = 'L'
    },
    cardView() {
      this.scenarioViewGroup = 'C'
    },
    imprClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
            this.requestImprRow.riskRegisterFlag = true
          }
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    /* eslint-disable no-unused-vars */
    callbackProxy(data, props, col) {
      if (col.name === 'beforeRiskEstimation') {
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'beforeExposureLevelFinal', data.f)
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'beforeToxic', data.s)
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'beforeRiskEstimation', data.r)
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'beforeRamMatrixRiskId', data.ramMatrixRiskId)
  
      } else {
          this.$set(this.process.charmResultScenarios[props.rowIndex], 'afterExposureLevelFinal', data.f)
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'afterToxic', data.s)
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'afterRiskEstimation', data.r)
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      }
      if (this.process.charmResultScenarios[props.rowIndex].editFlag !== 'C') {
        this.$set(this.process.charmResultScenarios[props.rowIndex], 'editFlag', 'U')
      }
    },
    callbackCardProxy(data, color, refName) {
      if (refName === 'beforeRiskEstimationProxy') {
        // 개선 전
        this.$set(this.scenario, 'beforeExposureLevelFinal', data.f)
        this.$set(this.scenario, 'beforeToxic', data.s)
        this.$set(this.scenario, 'beforeRiskEstimation', data.r)
        this.$set(this.scenario, 'beforeRamMatrixRiskId', data.ramMatrixRiskId)
        } else {
        // 개선 후
        this.$set(this.scenario, 'afterExposureLevelFinal', data.f)
        this.$set(this.scenario, 'afterToxic', data.s)
        this.$set(this.scenario, 'afterRiskEstimation', data.r)
        this.$set(this.scenario, 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      }
      this.$refs[refName].hide();
      this.$forceUpdate();
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.process.processCd}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if(recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ramCharmResultAssessScenarioId', recvObj.ramCharmResultAssessScenarioId)
      }
    },
    saveScenario() {
      if (this.$comm.validTable(this.grid.columns, this.process.charmResultScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.process.charmResultScenarios.splice(this.$_.findIndex(this.process.charmResultScenarios, item), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              this.updateBtnData.research = uid();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcess() {
      // 화면에서 추가는 하고 저장을 하지 않았을 시에 해당 데이터도 일괄 저장 처리
      // 저장 처리 후 callback에서 process 목록 재조회
      let isProgress = true;
      if (!this.process.charmResultScenarios || this.process.charmResultScenarios.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          // 공정 [' + this.process.processName + ']에 등록된 시나리오가 없습니다.
          message: this.$comm.getLangMessage('MSG0000399', {s1: this.process.processName}),
          type: 'warning', // success / info / warning / error
        });
      }

      if (isProgress && this.$comm.validTable(this.grid.columns, this.process.charmResultScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.process.chgUserId = this.$store.getters.user.userId
            this.process.ramProcessAssessStepCd = 'RPA0000005'; //대상공정 진행상태 '완료'

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcessCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('assessComplete', _result.data)

      this.updateBtnData.research = uid();
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진';
      this.popupOptions.param = {
        ramCharmResultAssessScenarioId: props.row.ramCharmResultAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        ramCharmResultAssessImprIds: props.row.ramCharmResultAssessImprIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/charm/action/charmScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setTrClass(props) {
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ramCharmResultAssessScenarioId ? 'bg-purple-2' : '';
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.scenario-view-group 
  button
    margin: 0 !important

.scenario-card-title
  margin-left: -5px
  font-size: 0.8rem

.scenario-card-side
  text-align: center
  align-self: center
  // flex-basis: 50%

.card-risk-item
  padding-top: 0px
  padding-bottom: 0px
  min-height: auto
.card-risk-info-charm
  margin-top: 0px
  width: auto
  min-width: 120px
  max-width: 100%
  flex: 10000 1 0%
  display: flex
  border-radius: 10px
  font-size: 1rem
  height: 40px
.before-risk
  background: #3A98B9
  margin-right: 5px
.after-risk
  background: #3A98B9
  margin-left: 5px
.risk-number
  font-size: 1.3rem

.detail-risk
  font-size: 0.9rem
  font-weight: 600
.detail-risk-num-charm
  font-size: 1rem
  font-weight: 700
  color: #e63946
  height: 40px

.card-scenario-list
  padding: 0px 16px !important
  min-height: 28px

.upload-picture
  width: 200px
  height: 120px
  height: calc(var(--height-var) - 5px)
  object-fit: fill
</style>
